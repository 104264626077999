import './VideoTab.css'

import { CopyFilled, CopyTwoTone } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Intent } from '@blueprintjs/core'
import { Button, Radio, Select } from 'antd'
import { TimePicker } from 'antd'
import { Spin } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import axios from 'axios'
import { Colors, RED } from 'common/colors'
import { Doctor, VideoSegment } from 'common/models'
import { Spacing } from 'common/stylings'
import KLCSVViewer from 'components/CSVViewer/KLCSVViewer'
import KLIndivNiftiViewer from 'components/KL3DViewer/KLIndivNiftiViewer'
import KLIndivSTLViewer from 'components/KL3DViewer/KLIndivSTLViewer'
import KLNiftiViewer from 'components/KL3DViewer/KLNiftiViewer'
import KLSTLViewer from 'components/KL3DViewer/KLSTLViewer'
import Stack from 'components/Stack'
import Toaster from 'components/Toaster'
import Text from 'components/Typography'
import KLZipFileViewer from 'components/ZipViewer/KLZipFileViewer'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _, { get } from 'lodash'
import { stringify } from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getUserIdOfCurrUser } from 'store/authentication/selectors'
import { getAllDoctors } from 'store/doctor/selectors'
import { RootState } from 'store/rootReducer'
import {
  createLoadingSelector,
  createSuccessSelector,
} from 'store/status/selectors'
import {
  extractFrames,
  extractVideoFrames,
  FrameType,
  uploadToDarwin,
  uploadToEncord,
  uploadToSuperAnnotate,
  uploadVideoToSpatialLabelingApp,
} from 'store/videoSegments/actionCreators'
import {
  addVideoImportLogFlow,
  editVideoCountFlow,
  extractFramesFlow,
  extractToDarwinFlow,
  fetchPublicVideoURLFlow,
  getSpatialLabelingAPITokenFlow,
  getVideoFramesFlow,
  uploadToEncordFlow,
  uploadToSuperAnnotateFlow,
  uploadVideoToSpatialLabelingAppFlow,
} from 'store/videoSegments/actions'

dayjs.extend(customParseFormat)

type Props = OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface OwnProps {
  video: VideoSegment
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  allDoctors?: Dict<Doctor>
  isVideo: boolean
  isDicomZip: boolean
  isCSV: boolean
  isNifti: boolean
  isNiftiZip: boolean
  isSTLZip: boolean
  isSTL: boolean
  toggleVisible: any
}

const VideoModal: React.FC<Props> = ({
  video,
  fetchPublicVideoURL,
  publicURL,
  editVideoCount,
  addVideoImportLog,
  getVideoFrames,
  extractFrames,
  uploadToDarwin,
  isExtracting,
  currUserId,
  isExportedToDarwin,
  setActiveTab,
  isUploadedToDarwin,
  isExportingToDarwin,
  isUploadingToDarwin,
  uploadToSuperAnnotate,
  isUploadingToSuperAnnotate,
  isUploadedToSuperAnnotate,
  allDoctors,
  isVideo,
  isDicomZip,
  isCSV,
  isNifti,
  isNiftiZip,
  isSTLZip,
  isSTL,
  getSpatialLabelingAPIToken,
  uploadVideoToSpatialLabelingApp,
  isUploadingToSpatialLabelingApp,
  toggleVisible,
  uploadToEncord,
  isUploadingToEncord,
  isUploadedToEncord,
}) => {
  const bucketName = video.uri.split('/')[2]
  const assetName = video.uri.split(bucketName + '/')[1]
  const [threeDAssetFileNames, setThreeDAssetFileNames] = React.useState<
    string[]
  >([])
  const [threeDAssetUrls, setThreeDAssetUrls] = React.useState<string[]>([])
  const [clickCopy, setClickCopy] = React.useState(false)
  const [clickExport, setClickExport] = React.useState(false)
  const [clickExportEncord, setClickExportEncord] = React.useState(false)
  const [assetURL, setAssetURL] = React.useState('')
  const [csvURL, setCSVURL] = React.useState('')
  const exportButtonDarwinRef = useRef(false)
  const exportButtonSuperAnnotateRef = useRef(false)
  const exportButtonEncordRef = useRef(false)
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false)
  const { user } = useAuth0()

  // state hooks
  const [timeRangeStatus, setTimeRangeError] = useState({
    isAbleToExtract: false,
    isError: false,
    message: '',
  })
  const [secondsPlayed, setSecondsPlayed] = useState(0)

  const [desiredFPS, setDesiredFPS] = useState(0)

  const [
    destinationProjectSpatialLabeling,
    setDestinationProjectSpatialLabeling,
  ] = useState('Select Project')

  const [destinationProjectSuperAnnotate, setDestinationProjectSuperAnnotate] =
    useState('Select Project')

  const [destinationFolderEncord, setDestinationFolderEncord] =
    useState('Select Folder')

  const [framePayload, setFrameParams] = useState({
    startTime: 0,
    endTime: 0,
    bucketName,
    assetName,
    frameType: '',
    videoId: video._id,
  })
  let playerRef: ReactPlayer

  const [darwinLogPayload, setDarwinLogPayload] = useState<any>([])

  const [superAnnotateLogPayload, setSuperAnnotateLogPayload] = useState<any>(
    [],
  )

  const [encordLogPayload, setEncordLogPayload] = useState<any>([])

  useEffect(() => {
    setFrameParams({
      ...framePayload,
      frameType: FrameType.ENCORD,
    })
  }, [])

  const setPlayerRef = (ref: ReactPlayer) => {
    playerRef = ref
  }

  const getURL = () => {
    if (!video.publicURL) {
      const payload = { assetName, bucketName }
      fetchPublicVideoURL(payload, video._id, video.batch)
    }
  }

  const renderExportButtonSuperAnnotate = () => {
    if (
      framePayload.frameType === FrameType.SPATIAL_LABELING ||
      framePayload.frameType === FrameType.ENCORD
    ) {
      return
    }

    const toggleAfterExport = async () => {
      const videoURI = video.uri
      const videoTags = [
        ...(video.tags || []),
        ...(video.classificationTags || []),
      ]

      const videoRegion =
        video.region != null && video.region.trim() !== ''
          ? video.region
          : 'No region specified'
      const doctorID = video.doctor
      const videoDoctor = get(allDoctors[doctorID], 'fullname', '')
      const videoName = video.filename
      const videoDoctorEmail = get(allDoctors[doctorID], 'email', '')
      const userEmail = user?.name ?? 'No email specified'
      const dateTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      })
      const timeOfImport = dateTime.toString()

      const dateNow = new Date().toISOString()
      const slicedDate = dateNow.slice(0, -1)
      const uploadDate = slicedDate.concat('000Z')

      const payloadSuperAnnotate = {
        videoURI,
        videoTags,
        destinationProjectSuperAnnotate,
        videoDoctor,
        videoRegion,
      }

      const logPayload = {
        videoName,
        videoDoctorEmail,
        userEmail,
        timeOfImport,
        uploadDate,
        videoRegion,
      }

      setSuperAnnotateLogPayload(logPayload)

      try {
        uploadToSuperAnnotate(payloadSuperAnnotate)
        exportButtonSuperAnnotateRef.current = true
        setClickExport(true)
      } catch (e) {
        Toaster.show({
          icon: 'cross',
          intent: Intent.DANGER,
          message: 'Failed to export video to Super Annotate',
        })
        console.log(e)
      }
    }

    return (
      <>
        {!clickExport &&
        destinationProjectSuperAnnotate !== 'Select Project' ? (
          <Stack justifyContent="flex-start">
            <Button type="primary" onClick={toggleAfterExport}>
              Export to SuperAnnotate
            </Button>
          </Stack>
        ) : (
          <Stack justifyContent="flex-start">
            <Button type="primary" disabled>
              Export to SuperAnnotate
            </Button>
            <Stack style={{ paddingLeft: 30 }}>
              {isUploadingToSuperAnnotate ? (
                <div>
                  <Spin tip="Uploading to Super Annotate..." />
                </div>
              ) : null}
            </Stack>
          </Stack>
        )}
        <Stack vertical style={{ paddingTop: 10, paddingBottom: 10 }}>
          {`${video.filename} has been exported to Super Annotate ${
            video.export_count ? video.export_count : 0
          } times.`}
        </Stack>
        <Stack vertical style={{ paddingTop: 10, paddingBottom: 10 }}>
          {`${video.filename} has been downloaded ${
            video.download_count ? video.download_count : 0
          } times.`}
        </Stack>
      </>
    )
  }

  const renderExportButtonEncord = () => {
    const toggleAfterExport = async () => {
      const videoURI = video.uri
      const videoTags = [
        ...(video.tags || []),
        ...(video.classificationTags || []),
      ]

      const videoRegion =
        video.region != null && video.region.trim() !== ''
          ? video.region
          : 'No region specified'
      const doctorID = video.doctor
      const videoDoctor = get(allDoctors[doctorID], 'fullname', '')
      const videoName = video.filename
      const videoDoctorEmail = get(allDoctors[doctorID], 'email', '')
      const userEmail = user?.name ?? 'No email specified'
      const dateTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      })
      const timeOfImport = dateTime.toString()

      const dateNow = new Date().toISOString()
      const slicedDate = dateNow.slice(0, -1)
      const uploadDate = slicedDate.concat('000Z')

      const payloadEncord = {
        videoURI,
        videoTags,
        destinationFolderEncord,
        videoDoctor,
        videoRegion,
      }

      const logPayload = {
        videoName,
        videoDoctorEmail,
        userEmail,
        timeOfImport,
        uploadDate,
        videoRegion,
      }

      setEncordLogPayload(logPayload)

      try {
        uploadToEncord(payloadEncord)
        exportButtonEncordRef.current = true
        setClickExportEncord(true)
      } catch (e) {
        Toaster.show({
          icon: 'cross',
          intent: Intent.DANGER,
          message: 'Failed to export video to Encord',
        })
        console.log(e)
      }
    }

    return (
      <>
        {!clickExportEncord && destinationFolderEncord !== 'Select Folder' ? (
          <Stack justifyContent="flex-start">
            <Button type="primary" onClick={toggleAfterExport}>
              Export to Encord
            </Button>
          </Stack>
        ) : (
          <Stack justifyContent="flex-start">
            <Button type="primary" disabled>
              Export to Encord
            </Button>
            <Stack style={{ paddingLeft: 30 }}>
              {isUploadingToEncord ? (
                <div>
                  <Spin tip="Uploading to Encord..." />
                </div>
              ) : null}
            </Stack>
          </Stack>
        )}
        <Stack vertical style={{ paddingTop: 10, paddingBottom: 10 }}>
          {`${video.filename} has been exported to Encord ${
            video.export_count ? video.export_count : 0
          } times.`}
        </Stack>
        <Stack vertical style={{ paddingTop: 10, paddingBottom: 10 }}>
          {`${video.filename} has been downloaded ${
            video.download_count ? video.download_count : 0
          } times.`}
        </Stack>
      </>
    )
  }

  const renderExportButton = () => {
    if (framePayload.frameType !== FrameType.DARWIN) {
      return
    }

    const isValid = [false]
    const timePoints: number[] = []

    Object.entries(inputList).forEach(([key, value], index) => {
      const newReg = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/
      const timePoint = value['timePoint']
      isValid[index] = newReg.test(timePoint) ? true : false
      timePoints.push(hmsToSecondsOnly(timePoint))
    })

    const toggleAfterExport = async () => {
      const videoURI = video.uri
      const presignedURL = video.publicURL
      const videoTags = video.tags

      // Convert NaN values to 0
      for (const timePoint in timePoints) {
        timePoints[timePoint] = timePoints[timePoint]
          ? timePoints[timePoint]
          : 0
      }

      const payloadManualExtraction = {
        videoURI,
        presignedURL,
        videoTags,
        timePoints,
      }

      try {
        extractFrames(payloadManualExtraction)
        exportButtonDarwinRef.current = true
        setClickExport(true)
      } catch (e) {
        Toaster.show({
          icon: 'cross',
          intent: Intent.DANGER,
          message: 'Failed to export video to Darwin',
        })
        console.log(e)
      }
    }

    return !clickExport && isValid.every(Boolean) ? (
      <Stack justifyContent="flex-start">
        <Button type="primary" onClick={toggleAfterExport}>
          Export to Darwin
        </Button>
      </Stack>
    ) : (
      <Stack justifyContent="flex-start">
        <Button type="primary" disabled>
          Export to Darwin
        </Button>
        <Stack style={{ paddingLeft: 30 }}>
          {isExportingToDarwin ? (
            <div>
              <Spin tip="Uploading to Darwin..." />
            </div>
          ) : null}
        </Stack>
      </Stack>
    )
  }

  // React.useEffect(() => {
  //   if (
  //     exportButtonDarwinRef.current &&
  //     (isExportedToDarwin || isUploadedToDarwin)
  //   ) {
  //     editVideoCount({
  //       videoId: video._id,
  //       export_count: video.export_count + 1,
  //       batch: video.batch,
  //     })
  //     addVideoImportLog(darwinLogPayload)
  //     setDarwinLogPayload([])
  //     exportButtonDarwinRef.current = false
  //   }
  // }, [isExportedToDarwin, isUploadedToDarwin])

  React.useEffect(() => {
    if (exportButtonSuperAnnotateRef.current && isUploadedToSuperAnnotate) {
      editVideoCount({
        videoId: video._id,
        export_count: video.export_count + 1,
        batch: video.batch,
      })
      addVideoImportLog(superAnnotateLogPayload)
      setSuperAnnotateLogPayload([])
      exportButtonSuperAnnotateRef.current = false
    }
  }, [isUploadedToSuperAnnotate])

  React.useEffect(() => {
    if (exportButtonEncordRef.current && isUploadedToEncord) {
      editVideoCount({
        videoId: video._id,
        export_count: video.export_count + 1,
        batch: video.batch,
      })
      addVideoImportLog(encordLogPayload)
      setEncordLogPayload([])
      exportButtonEncordRef.current = false
    }
  }, [isUploadedToEncord])

  const checkTimeRangeBoundary = () => {
    const { startTime, endTime } = framePayload
    if (
      playerRef &&
      playerRef!.getDuration() &&
      endTime > playerRef!.getDuration()
    ) {
      return setTimeRangeError({
        isAbleToExtract: false,
        isError: true,
        message: 'End time must be smaller than the duration of the video',
      })
    }
    if (endTime < startTime) {
      return setTimeRangeError({
        isAbleToExtract: false,
        isError: true,
        message: 'End time must be greater than start time',
      })
    }
    if (endTime - startTime > 60) {
      return setTimeRangeError({
        isAbleToExtract: false,
        isError: true,
        message: 'The time range must be within 1 minute',
      })
    }
    return setTimeRangeError({
      isAbleToExtract: true,
      isError: false,
      message: '',
    })
  }

  const checkTimeForCurrFrame = () => {
    if (framePayload.frameType !== FrameType.CURR_FRAME) {
      return
    }
    if (playerRef) {
      const currTimeInSeconds = Math.round(secondsPlayed)
      setFrameParams({
        ...framePayload,
        startTime: currTimeInSeconds,
        endTime: currTimeInSeconds,
      })
    }
  }

  const onClick = () => {
    if (!timeRangeStatus.isError && timeRangeStatus.isAbleToExtract) {
      // getVideoFrames(framePayload, currUserId, setActiveTab)
      getVideoFrames(framePayload, currUserId)
    }
  }

  // effect hooks
  useEffect(getURL, [])

  useEffect(checkTimeRangeBoundary, [
    framePayload.startTime,
    framePayload.endTime,
  ])

  useEffect(checkTimeForCurrFrame, [framePayload.frameType, secondsPlayed])

  const getAssetsURL = () => {
    try {
      const bucketName = video.uri.split('/')[2]
      const assetName = video.uri.split(bucketName + '/')[1]
      const basePath = assetName.split('/').slice(0, -1).join('/')

      const timestampMatch = assetName.match(
        /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}(\d{3})?[A-Z]/,
      )

      if (!timestampMatch) {
        throw new Error('Timestamp not found in the URI')
      }

      const timestamp = timestampMatch[0]
      const uniqueSuffix = timestamp.split('.')[1]

      const csvBasePath = basePath.replace(/-Study_\d+/, '-batch')
      const csvName = `${csvBasePath}/asset-0-${uniqueSuffix}.csv`

      const params = { assetName, bucketName }
      const csvParams = { assetName: csvName, bucketName }

      axios
        .get(`/video/get-public-url?${stringify(params)}`)
        .then(res => {
          const resPayload = {
            publicURL: get(res, ['data', 'publicURL'], ''),
          }
          setAssetURL(resPayload.publicURL)
        })
        .catch(err => console.log(err))

      axios
        .get(`/video/get-public-url?${stringify(csvParams)}`)
        .then(res => {
          const resPayload = {
            publicURL: get(res, ['data', 'publicURL'], ''),
          }
          setCSVURL(resPayload.publicURL)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.error('Error obtaining URLs:', error)
    }
  }

  const get3DAssetsUrlAndNames = async () => {
    try {
      const bucketName = video.uri.split('/')[2]
      let assetName = video.uri.split(bucketName + '/')[1]

      assetName = assetName.replace(/\.[^/.]+$/, '')

      if (!assetName.endsWith('/')) {
        assetName += '/'
      }

      const params = { folderKey: assetName, bucketName }

      const response = await axios.get(
        `/video/get-folder-urls?${stringify(params)}`,
      )

      const folderFiles = get(response, ['data', 'folderFiles'], [])

      const fileUrls = folderFiles.map(
        (file: { fileUrl: string }) => file.fileUrl,
      )
      const fileNames = folderFiles.map(
        (file: { fileName: string }) => file.fileName,
      )

      setThreeDAssetUrls(fileUrls)
      setThreeDAssetFileNames(fileNames)
    } catch (error) {
      console.error('Error obtaining 3D asset URLs:', error)
      setThreeDAssetUrls([])
      setThreeDAssetFileNames([])
    }
  }

  useEffect(() => {
    if (isNiftiZip || isSTLZip) {
      get3DAssetsUrlAndNames()
    }
  }, [video.uri, isNiftiZip, isSTLZip])

  React.useEffect(() => getAssetsURL(), [assetURL])

  const renderVideoPlayer = () => {
    if (!assetURL) {
      return <div>Loading video...</div>
    }

    return (
      <ReactPlayer
        ref={setPlayerRef}
        url={assetURL}
        controls
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        height={'auto'}
        width={'100%'}
        onSeek={setSecondsPlayed}
      />
    )
  }

  const handleDownloadClick = () => {
    if (!isDownloaded) {
      editVideoCount({
        videoId: video._id,
        download_count: video.download_count + 1,
        batch: video.batch,
      })
      setIsDownloaded(true)
    }
  }

  const renderDownloadButton = () => {
    return (
      <Stack justifyContent="flex-start">
        <a
          href={assetURL}
          download
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
          onClick={e => {
            if (isDownloaded) {
              e.preventDefault()
            } else {
              handleDownloadClick()
            }
          }}>
          <Button
            disabled={isDownloaded}
            type="primary"
            style={{ marginTop: '1rem' }}>
            Download video
          </Button>
        </a>
      </Stack>
    )
  }

  const renderExportToSLAButton = () => {
    if (framePayload.frameType === FrameType.SPATIAL_LABELING) {
      const handleClick = async () => {
        try {
          const accessToken = await getSpatialLabelingAPIToken()
          if (!accessToken) {
            console.error('Failed to retrieve token')
            return
          }

          const projectName = destinationProjectSpatialLabeling
          const videoName = video.filename
          const videoURI = video.uri
          const fps = 30
          const region =
            video.region != null && video.region.trim() !== ''
              ? video.region
              : 'No region specified'
          const doctorID = video.doctor
          const surgeon = get(allDoctors[doctorID], 'fullname', '')
          const videoTags = [
            ...(video.tags || []),
            ...(video.classificationTags || []),
          ]

          const data = await uploadVideoToSpatialLabelingApp(
            accessToken,
            projectName,
            videoName,
            videoURI,
            fps,
            region,
            surgeon,
            videoTags,
          )
          if (
            (data as unknown as { message: string }).message ===
            'Project created and video uploaded successfully'
          ) {
            toggleVisible(false)
          }
        } catch (error) {
          console.error('Failed to retrieve access token', error)
        }
      }

      return (
        <Stack
          vertical
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginBottom: '1rem' }}>
          {renderDestProjectsSpatialLabeling()}

          <Stack justifyContent="flex-start" alignItems="center">
            <Button
              onClick={handleClick}
              type="primary"
              disabled={
                isUploadingToSpatialLabelingApp ||
                destinationProjectSpatialLabeling === 'Select Project'
              }>
              Export to Spatial Labeling App
            </Button>

            {isUploadingToSpatialLabelingApp && (
              <div style={{ paddingLeft: 30 }}>
                <Spin tip="Exporting to Spatial Labeling App..." />
              </div>
            )}
          </Stack>
        </Stack>
      )
    }

    return null
  }

  function hmsToSecondsOnly(str: any) {
    const splitString = str.split(':')
    let seconds = 0
    let minutes = 1

    while (splitString.length > 0) {
      seconds += minutes * parseInt(splitString.pop(), 10)
      minutes *= 60
    }

    return seconds
  }

  const renderTimeSelectionInputs = () => {
    function onChangeStartTime(time: any, timeString: any) {
      setFrameParams({
        ...framePayload,
        startTime: hmsToSecondsOnly(timeString),
      })
    }

    function onChangeEndTime(time: any, timeString: any) {
      setFrameParams({
        ...framePayload,
        endTime: hmsToSecondsOnly(timeString),
      })
    }

    return (
      <Stack style={{ paddingBottom: 10, paddingTop: 8 }}>
        <Stack style={{ paddingRight: 5, paddingTop: 8 }}>Start Time</Stack>
        <TimePicker
          onChange={onChangeStartTime}
          defaultValue={dayjs('00:00:00', 'HH:mm:ss')}
          size="large"
        />
        <Stack style={{ paddingLeft: 50, paddingRight: 5, paddingTop: 8 }}>
          End Time
        </Stack>
        <TimePicker
          onChange={onChangeEndTime}
          defaultValue={dayjs('00:00:00', 'HH:mm:ss')}
          size="large"
        />
      </Stack>
    )
  }

  const [inputList, setInputList] = useState([{ timePoint: '' }])

  // handle input change
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const renderFPSDropdown = () => {
    if (framePayload.frameType !== FrameType.DARWIN_PIPELINE) {
      return
    }
    const selectedFPS = String(desiredFPS)

    const { Option } = Select

    const handleChange = (value: string) => {
      setDesiredFPS(parseInt(value))
    }

    return (
      <Stack>
        <Stack style={{ paddingRight: 8, paddingTop: 5, paddingBottom: 10 }}>
          Desired FPS
        </Stack>
        <Select
          defaultValue={selectedFPS}
          style={{ width: 140 }}
          onChange={handleChange}>
          <Option value="1">1</Option>
          <Option value="3">3</Option>
          <Option value="5">5</Option>
        </Select>
      </Stack>
    )
  }

  const renderDestProjectsSpatialLabeling = () => {
    if (framePayload.frameType !== FrameType.SPATIAL_LABELING) {
      return
    }

    const selectedDestProject = String(destinationProjectSpatialLabeling)

    const { Option } = Select

    const handleChange = (value: string) => {
      setDestinationProjectSpatialLabeling(value)
    }

    return (
      <Stack style={{ paddingRight: 8, paddingTop: 8, paddingBottom: 15 }}>
        <Stack style={{ paddingTop: 5, paddingRight: 8 }}>
          Destination Project in Spatial Labeling App
        </Stack>
        <Select
          defaultValue={selectedDestProject}
          style={{ width: 250 }}
          onChange={handleChange}>
          <Option value="Destination Project 1">Destination Project 1</Option>
          <Option value="Destination Project 2">Destination Project 2</Option>
          <Option value="Destination Project 3">Destination Project 3</Option>
          <Option value="Destination Project 4">Destination Project 4</Option>
          <Option value="Destination Project 5">Destination Project 5</Option>
        </Select>
      </Stack>
    )
  }

  const renderDestProjectsSuperAnnotate = () => {
    if (
      framePayload.frameType === FrameType.SPATIAL_LABELING ||
      framePayload.frameType === FrameType.ENCORD
    ) {
      return
    }

    const selectedDestProject = String(destinationProjectSuperAnnotate)

    const { Option } = Select

    const handleChange = (value: string) => {
      setDestinationProjectSuperAnnotate(value)
    }

    return (
      <Stack style={{ paddingRight: 8, paddingTop: 8, paddingBottom: 15 }}>
        <Stack style={{ paddingTop: 5, paddingRight: 8 }}>
          Destination Projects in Super Annotate:
        </Stack>
        <Select
          defaultValue={selectedDestProject}
          style={{ width: 250 }}
          onChange={handleChange}>
          <Option value="Sandbox-Videos">Sandbox-Videos</Option>
          <Option value="Knee-Videos">Knee-Videos</Option>
          <Option value="Shoulder-Videos">Shoulder-Videos</Option>
          <Option value="Hip-Videos">Hip-Videos</Option>
          <Option value="Activity Recognition">Activity Recognition</Option>
          <Option value="Medusa-Integration-1FPS-A">
            Medusa-Integration-1FPS-A
          </Option>
          <Option value="Medusa-Integration-1FPS-B">
            Medusa-Integration-1FPS-B
          </Option>
          <Option value="Medusa-Integration-1FPS-C">
            Medusa-Integration-1FPS-C
          </Option>
        </Select>
      </Stack>
    )
  }

  const renderDestFoldersEncord = () => {
    if (
      framePayload.frameType === '' ||
      framePayload.frameType === FrameType.SPATIAL_LABELING ||
      framePayload.frameType === FrameType.SUPER_ANNOTATE
    ) {
      return
    }

    const selectedDestFolder = String(destinationFolderEncord)

    const { Option } = Select

    const handleChange = (value: string) => {
      setDestinationFolderEncord(value)
    }

    return (
      <Stack style={{ paddingRight: 8, paddingTop: 8, paddingBottom: 15 }}>
        <Stack style={{ paddingTop: 5, paddingRight: 8 }}>
          Destination Folders in Encord:
        </Stack>
        <Select
          defaultValue={selectedDestFolder}
          style={{ width: 250 }}
          onChange={handleChange}>
          <Option value="Knee-Videos">Knee-Videos</Option>
          <Option value="Shoulder-Videos">Shoulder-Videos</Option>
          <Option value="Hip-Videos">Hip-Videos</Option>
        </Select>
      </Stack>
    )
  }

  const renderExtractionButtons = () => {
    return (
      <div>
        <Stack style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Text
            color={Colors.DARK}
            style={{
              marginRight: '5px',
              fontSize: 16,
              fontWeight: 450,
            }}>
            Export to:{' '}
          </Text>
          <Radio.Group
            defaultValue={FrameType.ENCORD}
            onChange={(e: RadioChangeEvent) =>
              setFrameParams({
                ...framePayload,
                startTime: 0,
                endTime: 0,
                frameType: e.target.value,
              })
            }>
            {/* <Radio style={{ fontSize: 15 }} value={FrameType.ALL_FRAMES}>
              All
            </Radio>
            <Radio style={{ fontSize: 15 }} value={FrameType.CURR_FRAME}>
              Single
            </Radio> */}
            {/* <Radio style={{ fontSize: 15 }} value={FrameType.SUPER_ANNOTATE}>
              Super Annotate
            </Radio> */}
            <Radio style={{ fontSize: 15 }} value={FrameType.ENCORD}>
              Encord
            </Radio>
            <Radio style={{ fontSize: 15 }} value={FrameType.SPATIAL_LABELING}>
              Spatial Labeling
            </Radio>
          </Radio.Group>
        </Stack>
        <Stack style={{ paddingBottom: 10 }}>
          {framePayload.frameType === FrameType.ALL_FRAMES &&
            renderTimeSelectionInputs()}
        </Stack>
        {timeRangeStatus.isError && (
          <Text color={RED}>{timeRangeStatus.message}</Text>
        )}
        {/* <Stack
          justifyContent="flex-start"
          style={{ paddingBottom: 10, paddingTop: 5 }}>
          <Button
            type="primary"
            loading={isExtracting}
            disabled={!timeRangeStatus.isAbleToExtract}
            onClick={onClick}>
            Extract
          </Button>
        </Stack> */}
      </div>
    )
  }

  const renderCopyButton = () => {
    const toggleAfterCopy = () => {
      setClickCopy(true)
      navigator.clipboard.writeText(video.uri)
      Toaster.show({
        icon: 'tick',
        intent: Intent.SUCCESS,
        message: 'Copied to Clipboard!',
      })
    }
    return !clickCopy ? (
      <CopyTwoTone
        style={{ marginLeft: '5px' }}
        onClick={() => toggleAfterCopy()}></CopyTwoTone>
    ) : (
      <CopyFilled style={{ marginLeft: '5px' }}> </CopyFilled>
    )
  }

  return isVideo ? (
    <Stack vertical gutter={Spacing.MEDIUM}>
      <Stack vertical>
        <Text
          style={{
            marginTop: '15px',
            fontSize: '18px',
          }}
          fontSize={16}
          fontWeight="bold">
          {video.filename}
        </Text>
        <Text
          style={{
            marginBottom: '15px',
            fontSize: '12.5px',
          }}
          color={Colors.PALE_GREY}
          fontSize={12}>
          {video.uri}
          {renderCopyButton()}
        </Text>
      </Stack>
      {renderVideoPlayer()}
      {renderDownloadButton()}
      {renderExtractionButtons()}
      {/* {renderTimeFrame()} */}
      {renderFPSDropdown()}
      {/* {renderDestProjectsSuperAnnotate()}
      {renderExportButtonSuperAnnotate()} */}
      {renderExportButton()}
      {renderDestFoldersEncord()}
      {renderExportToSLAButton()}
      {framePayload.frameType === FrameType.ENCORD &&
        renderExportButtonEncord()}
    </Stack>
  ) : isDicomZip ? (
    <div>
      <KLZipFileViewer
        url={assetURL}
        video={video}
        csvURL={csvURL}
        toggleVisible={toggleVisible}
      />
    </div>
  ) : isCSV ? (
    <KLCSVViewer url={assetURL} video={video} csvName={video.filename} />
  ) : isNiftiZip ? (
    <KLNiftiViewer
      niftiZipFileUrl={assetURL}
      niftiAssetFileNames={threeDAssetFileNames}
      niftiUrls={threeDAssetUrls}
      video={video}
      fileName={video.filename}
    />
  ) : isNifti ? (
    <KLIndivNiftiViewer
      niftiFileUrl={assetURL}
      video={video}
      fileName={video.filename}
    />
  ) : isSTLZip ? (
    <KLSTLViewer
      stlZipFileUrl={assetURL}
      stlAssetFileNames={threeDAssetFileNames}
      stlUrls={threeDAssetUrls}
      video={video}
      fileName={video.filename}
    />
  ) : isSTL ? (
    <KLIndivSTLViewer
      stlFileUrl={assetURL}
      video={video}
      fileName={video.filename}
    />
  ) : null
}

const getFrameLoadingSelector = createLoadingSelector([
  extractVideoFrames.request,
])

const getExtractFramesSuccessSelector = createSuccessSelector([
  extractFrames.success,
])

const getUploadToDarwinSuccessSelector = createSuccessSelector([
  uploadToDarwin.success,
])

const getExtractFramesLoadingSelector = createLoadingSelector([
  extractFrames.request,
])

const getUploadToDarwinLoadingSelector = createLoadingSelector([
  uploadToDarwin.request,
])

const getUploadToSuperAnnotateLoadingSelector = createLoadingSelector([
  uploadToSuperAnnotate.request,
])

const getUploadToSuperAnnotateSuccessSelector = createSuccessSelector([
  uploadToSuperAnnotate.success,
])

const getUploadToEncordLoadingSelector = createLoadingSelector([
  uploadToEncord.request,
])

const getUploadToEncordSuccessSelector = createSuccessSelector([
  uploadToEncord.success,
])

const getUploadVideoToSpatialLabelingAppLoadingSelector = createLoadingSelector(
  [uploadVideoToSpatialLabelingApp.request],
)

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  publicURL: _.get(props.video, 'publicURL', ''),
  isExtracting: getFrameLoadingSelector(state),
  currUserId: getUserIdOfCurrUser(state),
  isExportedToDarwin: getExtractFramesSuccessSelector(state),
  isUploadedToDarwin: getUploadToDarwinSuccessSelector(state),
  isExportingToDarwin: getExtractFramesLoadingSelector(state),
  isUploadingToDarwin: getUploadToDarwinLoadingSelector(state),
  isUploadingToSuperAnnotate: getUploadToSuperAnnotateLoadingSelector(state),
  isUploadedToSuperAnnotate: getUploadToSuperAnnotateSuccessSelector(state),
  isUploadingToEncord: getUploadToEncordLoadingSelector(state),
  isUploadedToEncord: getUploadToEncordSuccessSelector(state),
  isUploadingToSpatialLabelingApp:
    getUploadVideoToSpatialLabelingAppLoadingSelector(state),
  allDoctors: getAllDoctors(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchPublicVideoURL: fetchPublicVideoURLFlow,
      getVideoFrames: getVideoFramesFlow,
      extractFrames: extractFramesFlow,
      uploadToDarwin: extractToDarwinFlow,
      editVideoCount: editVideoCountFlow,
      addVideoImportLog: addVideoImportLogFlow,
      uploadToSuperAnnotate: uploadToSuperAnnotateFlow,
      uploadToEncord: uploadToEncordFlow,
      getSpatialLabelingAPIToken: getSpatialLabelingAPITokenFlow,
      uploadVideoToSpatialLabelingApp: uploadVideoToSpatialLabelingAppFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(VideoModal)
